<template>
  <Module v-if="isOwnedModules && !!rootHolder.root" :module="ownedModule" :root-holder="rootHolder" :show-target="true"/>
  <Concept v-else-if="isHealing" :parts="['props', 'health']" :labels="Game.commonLabels.props.health" :root-holder="rootHolder"/>
</template>

<script setup>
import {computed, onMounted} from 'vue';
import {Game} from '@/common';
import Module from '@/components/Module.vue';
import {api} from '@/api';
import Concept from '@/components/utils/Concept.vue';

const props = defineProps(
    {
      assignment: {type: Object, required: true},
    },
);

onMounted(() => {
  const target = targetRef.value;
  if (target.ownerType === 'colony' && !rootHolder.value.root) {
    api.loadColony(target.id);
  }
});

const targetRef = computed(() => props.assignment.target ?? {type: 'player', id: Game.playerId});

const isOwnedModules = computed(() => targetRef.value.type === 'ownedModules');

const isHealing = computed(() => targetRef.value.type === 'player' && props.assignment.type === 'healing');

const ownedModule = computed(() => {
  const target = targetRef.value;
  return Game.ownedModulesRecords[target.id].modules.find(m => m._id === target.subId);
});

const rootHolder = computed(() => {
  const target = targetRef.value;
  switch (target.ownerType ?? target.type) {
    case 'player': {
      return {type: 'player', root: Game.players[target.id]};
    }
    case 'colony':
      return {type: 'colony', root: Game.colonies[target.id]};
    default:
      return {};
  }
});

</script>