<template>
  <Row :image-path="`icons/artis/${props.id}.svg`" :labels="labels"
       :image-classes="imageClasses" row-class="arti" title-display="full" :test-identifier="testId">

    <template #titlePopupContent>
      <ArtiConcept :id="props.id" :arti="props.arti" :target-holder="targetHolder"/>
    </template>

    <template #totals>
      <div v-text="displayAmount"/>
    </template>

    <div class="-fc -flex-wrap">
      <DealConcept :deal="{gains: realArti.gains}" :mutex-infos="rootMutexInfos" test-identifier="artiDeal"/>
    </div>
    <div v-if="realArti.targetDeal" class="-fc -flex-wrap">
      <DealConcept :deal="realArti.targetDeal" :root-holder="targetHolder" :mutex-infos="targetMutexInfos"
                   :display-as-target="true" test-identifier="artiTargetDeal"/>
    </div>

    <template #buttons>
      <DealButton v-if="canUse" :action="use" :progress="props.arti" :research-id="researchId"
                  :deal="realArti" :target-deal="realArti.targetDeal" :target-holder="targetHolder"
                  :mutex-infos="mutexInfos" placeholder="effect" test-identifier="use">Use</DealButton>
      <DealButton v-if="utils.hasItems(realArti.shreds)" :action="shred" :deal="{gains: realArti.shreds}"
                  :show-gains="true" test-identifier="shred">Shred</DealButton>
    </template>
  </Row>
</template>

<script setup>
import DealButton from '@/components/utils/DealButton';
import {utils} from '@/helpers';
import Row from '@/components/utils/Row';
import ArtiConcept from '@/components/ArtiConcept';
import DealConcept from '@/components/utils/DealConcept';
import {api} from '@/api';
import {computed} from 'vue';
import {compositions} from '@/compositions';
import {playerHolder} from '@/common';

const props = defineProps(
    {
      ...compositions.propDefs.arti,
      ...compositions.propDefs.target,
    },
);

const { realArti, labels, amount, displayAmount, researchId, mutexRoot } = compositions.arti(props);

const { targetHolder, targetRootType } = compositions.target(props);

const { mutexInfos, rootMutexInfos, targetMutexInfos } = compositions.mux(mutexRoot, playerHolder, targetHolder);

const { isResearchNeeded } = compositions.research(researchId.value);

const canUse = computed(() => realArti.value.targetTypes === undefined ?
                              props.targetType === undefined :
                              realArti.value.targetTypes.includes(props.targetType));

const imageClasses = computed(() => isResearchNeeded.value ? ['-disabled'] : null);

const testId = computed(() => `Artifact::${props.id}`);

function use() {
  const target = targetRootType.value ? `/at/${targetRootType.value}/${props.targetId}` : '';
  api.post(`artis/${props.id}${target}`);
}

function shred() {
  const initial = String(Math.max(amount.value - 1, 1));
  const shredCount = Number(window.prompt(`${labels.value.name}:\nHow many to shred?`, initial));
  if (shredCount > 0) {
    api.del(`artis/${props.id}/${shredCount}`);
  }
}
</script>

<style>
.arti .row-image {
  max-width: 65px;
  padding: 5px;
}
</style>