import {computed} from 'vue';
import {Game, playerHolder} from '@/common';
import {api} from '@/api';
import {utils} from '@/helpers';

const mutexConfigs = {
  'MODULE': {
    getMutexed: (mutexId, root) =>
      Game.ownedModulesRecords[root._id]?.modules.filter(om => Game.modules[om.oid].mutexId === mutexId),
    filter: e => e.state !== 'parts',
    textCreator: (i, name) => withSuffix(i, name, `(${i.val}/${i.total} on)`),
  },
  'TEMP': {
    getMutexed: (mutexId, root) =>
      root.temps?.filter(t => t.mutexId === mutexId),
    filter: () => true,
    textCreator: (i, name) => withSuffix(i, name, `(${i.val} on)`),
  },
};

function withSuffix(i, name, suffix) {
  const toAppend = i.total > 0 ? ` ${suffix}` : '';
  return `${i.max} ${name}${toAppend}`;
}

function toMutexInfo(id, holder) {
  const mutex = Game.mutexes[id];
  const config = mutexConfigs[mutex.type];
  const info = (() => {
    if (!holder?.root) {
      return {val: 0, total: 0};
    }
    const total = config.getMutexed(mutex._id, holder.root) ?? [];
    return {
      val: total.filter(config.filter).length,
      total: total.length,
    };
  })();
  info.max = mutex.val;
  info.text = config.textCreator(info, Game.getLabels('mutexes', id).name);
  return info;
}

export const compositions = {
  crew() {
    const totalAssignedEngineersCount = computed(
      () => Object.values(Game.player.crew.engineers.assignments)
        .map(a => a.crewCount)
        .reduce((a, b) => a + b, 0));
    return { totalAssignedEngineersCount };
  },

  assignments(assignmentIdUnwrapped) {
    const {totalAssignedEngineersCount} = compositions.crew();
    const assignedEngineersCount = computed(
      () => Game.player.crew.engineers.assignments[assignmentIdUnwrapped]?.crewCount ?? 0);
    const totalEngineersCount = computed(() => Game.player.crew.engineers.val);
    const idleEngineersCount = computed(
      () => totalEngineersCount.value - totalAssignedEngineersCount.value);
    const hasIdleEngineers = computed(() => idleEngineersCount.value > 0);
    const inProgressMutations = computed(
      () => [getEngineersMutation(assignedEngineersCount.value)]);
    const isInProgress = computed(() => assignedEngineersCount.value > 0);

    function getEngineersMutation(count, permitted = false) {
      return {p1: 'crew', p2: 'engineers', val: count, permitted: permitted};
    }

    return {
      assignedEngineersCount,
      totalEngineersCount,
      idleEngineersCount,
      hasIdleEngineers,
      inProgressMutations,
      isInProgress,
      getEngineersMutation,
    };
  },

  research(researchIdUnwrapped) {
    const researchObj = computed(() => Game.researches[researchIdUnwrapped]);
    const researchProgressObj = computed(() => Game.playerResearches[researchIdUnwrapped]);
    const isResearchNeeded = computed(() => researchIdUnwrapped ? !researchProgressObj.value?.fin : false);
    const researchProgressVal = computed(() => researchProgressObj.value?.val ?? 0);
    const researchLeft = computed(() => researchObj.value?.val - researchProgressVal.value);
    return { researchProgressObj, isResearchNeeded, researchProgressVal, researchObj, researchLeft };
  },

  region() {
    const movementTask = computed(() => Game.player.tasks.find(t => t._id === Game.gameConf.ids.movementTask));
    const targetRegionId = computed(() => Game.movementInProgress ?
                                          movementTask.value.impact.find(i => i.p1 === 'regionId').val :
                                          undefined);
    return { movementTask, targetRegionId };
  },

  module(moduleOidUnwrapped) {
    const moduleObject = computed(() => Game.modules[moduleOidUnwrapped]);
    const labels = computed(() => Game.getLabels('modules', moduleOidUnwrapped));
    return { moduleObject, labels };
  },

  modules(ownerIdUnwrapped) {
    const modulesRecord = computed(() => Game.ownedModulesRecords[ownerIdUnwrapped]);
    return { modulesRecord };
  },

  // propDefs.mission
  mission(props) {
    const id = computed(() => props.mission._id);
    const labels = computed(() => Game.getLabels('missions', id.value));
    return { id, labels };
  },

  // propDefs.arti
  arti(props) {
    const realArti = computed(() => Game.artis[props.id]);
    const labels = computed(() => Game.getLabels('artis', props.id));
    const amount = computed(() => props.arti.val ?? 0);
    const displayAmount = computed(() => utils.formatNumber(amount.value, 'full'));
    const researchId = computed(() => realArti.value.researchId);
    const mutexRoot = realArti;
    return { realArti, labels, amount, displayAmount, researchId, mutexRoot };
  },

  mux(mutexRoot, rootHolder, targetHolder) {
    const rootMutexInfos = computed(() => {
      const ids = [mutexRoot.value.mutexId];
      mutexRoot.value.gains
        ?.flatMap(g => g.mutexId)
        .forEach(id => ids.push(id));
      return ids
        .filter(Boolean)
        .map(id => toMutexInfo(id, rootHolder?.value));
    });

    const targetMutexInfos = computed(() => {
      return mutexRoot.value.targetDeal?.gains
               ?.flatMap(g => g.mutexId)
               .filter(Boolean)
               .map(id => toMutexInfo(id, targetHolder?.value)) ??
        [];
    });

    const mutexInfos = computed(() => [...rootMutexInfos.value, ...targetMutexInfos.value]);

    return { mutexInfos, rootMutexInfos, targetMutexInfos };
  },

  mutexesAtLimit(mutexInfos) {
    const mutexInfosAtLimit = computed(() => mutexInfos.value?.filter(m => m.val >= m.max) ?? []);
    const hasMutexesAtLimit = computed(() => mutexInfosAtLimit.value.length);
    return { mutexInfosAtLimit, hasMutexesAtLimit };
  },

  colony() {
    const accessibleColoniesList = computed(() => utils.sortBy(Game.playerColoniesRecord?.colonies ?? [], 'createdAt', true));
    const ownedColoniesCount = computed(() => accessibleColoniesList.value.filter(c => c.owned).length);
    const maxColoniesCount = computed(() => Math.ceil(Game.player.level / Game.gameConf.multipliers.levelsPerColony));
    return { accessibleColoniesList, ownedColoniesCount, maxColoniesCount };
  },

  // propDefs.target
  target(props) {
    const targetHolder = computed(() => {
      switch (props.targetType) {
        case 'npc':
          return {type: props.targetType, root: Game.playerNpcs[props.targetId]};
        case 'ownColony':
          return {type: 'colony', root: Game.colonies[props.targetId]};
        default:
          return null; // DealConcept expects it to be explicitly null
      }
    });

    const targetRootType = computed(() => {
      switch (props.targetType) {
        case 'npc':
          return props.targetType;
        case 'ownColony':
          return 'colony';
        default:
          return undefined;
      }
    });
    return { targetHolder, targetRootType };
  },

  researchesLoader(relatedResearchIdsWrapped) {
    const relatedResearchesPresent = computed(
      () => utils.allKeysPresent(Game.playerResearches, relatedResearchIdsWrapped.value));

    utils.watchAndTrigger(relatedResearchesPresent, () => !relatedResearchesPresent.value && api.loadResearchProgresses());

    return { relatedResearchesPresent };
  },

  // propDefs.numbers
  numbers(props) {
    function formatNumbers(values, format = undefined) {
      const formatToUse = format ?? props.numberFormat;
      const formatter = (value) => utils.formatNumber(value, formatToUse);
      return Array.isArray(values) ? values.map(formatter).join('') : formatter(values);
    }
    return { formatNumbers };
  },

  rootOrPlayerHolder(props) {
    return computed(() => props.rootHolder === undefined ? playerHolder.value : props.rootHolder);
  },

  propDefs: {
    testId: {
      testIdentifier: {type: String, default: '?'},
    },
    mission: {
      mission: {type: Object, required: true},
    },
    target: {
      targetId: {type: String},
      targetType: {type: String, validator(v) { return ['npc', 'ownColony'].includes(v) }},
    },
    arti: {
      arti: {type: Object, required: true},
      id: {type: String, required: true},
    },
    numbers: {
      numberFormat: {type: String, validator(v) { return ['compact', 'full'].includes(v) }, default: 'compact'},
    },
  },
};
