<template>
  <Row v-if="moduleObject" :image-path="`modules/${id}.jpg`" :image-classes="imageClasses"
       :labels="labels" row-class="module" :compact="true" :test-identifier="testId">
    <template #imageExtra>
      <RadialProgress v-if="task" :target="task" :classes="progressBarClasses"
                      :hints="{'top-right': 'clock', 'bottom-right': 'valueRemaining', 'top-left': 'max', 'bottom-left': 'value'}"/>
    </template>

    <template #titlePopupContent>
      <ModuleConcept :id="id" :root-holder="rootHolder"/>
    </template>

    <div class="-fc -flex-wrap" data-testid="moduleDeal">
      <div :class="gainMutationsClasses">
        <Mutations :list="moduleObject.gains" display="gain" :root-holder="rootHolder"/>
      </div>
      <div :class="costMutationsClasses">
        <Mutations :list="decksMutations" display="attention" :root-holder="rootHolder"/>
      </div>
      <Mutexes :mutex-infos="rootMutexInfos"/>
      <AssignmentHint :assignment-id="uid"/>
    </div>

    <template #buttons>
      <DealButton v-if="ability" :action="useAbility" :deal="ability.deal" :progress="module.ability" :show-gains="true"
                  :research-id="ability.researchId" :root-holder="rootHolder" :disabled="!isInstalled" test-identifier="useAbility">Use ability</DealButton>
      <CrewAssigner v-if="canForge" :api-path="apiPath" :assignment-id="uid + Game.gameConf.consts.idSeparator + rootHolder.root._id" :deal="forgingDeal"
                    :mutex-infos="forgingButtonMutexInfos" :button-label="forgingButtonLabel"
                    :research-id="moduleObject.researchId" :root-holder="rootHolder"/>
      <DealButton v-if="isUninstalled" :action="install" :deal="{costs: decksMutations}" :root-holder="rootHolder" test-identifier="install">Install</DealButton>
      <DealButton v-if="isInstalled" :action="uninstall" :deal="{gains: decksMutations}" :root-holder="rootHolder" :show-gains="true" test-identifier="uninstall">Uninstall</DealButton>
      <DealButton v-if="utils.hasItems(moduleObject.shreds)" :action="shred" :deal="{}" :target-deal="{gains: shredMutations}"
                  :root-holder="rootHolder" :target-holder="playerHolder" :show-gains="true" test-identifier="shred">Shred</DealButton>
    </template>
  </Row>
</template>

<script setup>
import {utils} from '@/helpers';
import Mutations from '@/components/utils/Mutations';
import DealButton from '@/components/utils/DealButton';
import RadialProgress from '@/components/utils/RadialProgress';
import Row from '@/components/utils/Row';
import CrewAssigner from '@/components/utils/CrewAssigner';
import AssignmentHint from '@/components/utils/AssignmentHint';
import ModuleConcept from '@/components/ModuleConcept';
import {api} from '@/api';
import {Game, playerHolder} from '@/common';
import {computed, ref, toRef} from 'vue';
import {compositions} from '@/compositions';
import Mutexes from '@/components/Mutexes';

const props = defineProps(
    {
      module: {type: Object, required: true},
      rootHolder: {type: Object},
      showTarget: {type: Boolean},
    }
);

const abilityUsed = ref(false);

const rootHolder = compositions.rootOrPlayerHolder(props);

const id = computed(() => props.module.oid);

function getEnhancedModuleComposition() {
  const mc = compositions.module(id.value);
  const targetName = props.rootHolder.root.name
  if (!props.showTarget || !targetName) {
    return mc;
  }
  const labels = utils.clone(mc.labels.value);
  labels.name += ' (@' + targetName + ')';
  return {
    moduleObject: mc.moduleObject,
    labels: labels,
  };
}

const { moduleObject, labels } = getEnhancedModuleComposition();

const { mutexInfos, rootMutexInfos } = compositions.mux(moduleObject, toRef(() => props.rootHolder));

const { hasMutexesAtLimit } = compositions.mutexesAtLimit(mutexInfos);

const { modulesRecord } = compositions.modules(rootHolder.value.root._id);

const uid = computed(() => props.module._id);

const task = computed(() => modulesRecord.value?.tasks.find(t => t._id === uid.value));

const state = computed(() => props.module.state);

const isInstalled = computed(() => state.value === 'on');

const isUninstalled = computed(() => state.value === 'off');

const isUsingDecks = computed(() => state.value === 'on' || state.value === 'forging');

const isForgingInProgress = computed(() => state.value === 'forging' && task.value?.uph > 0);

const isNotStarted = computed(() => state.value === 'parts');

const canForge = computed(() => state.value === 'parts' || state.value === 'forging');

const imageClasses = computed(
    () => addDisabledIf(abilityUsed.value ? ['animation-glowing', '-animation-fast', '-animation-once'] : [], !isInstalled.value));

const progressBarClasses = computed(() => addDisabledIf([], !isForgingInProgress.value));

const gainMutationsClasses = computed(() => addDisabledIf([], !isInstalled.value));

const costMutationsClasses = computed(() => addDisabledIf([], !isUsingDecks.value));

const decksMutations = computed(() => {
  const size = moduleObject.value.size;
  return [{p1: 'props', p2: 'decks', p3: 'val', val: size}];
});

const forgingDeal = computed(() => {
  const costs = isNotStarted.value && !hasMutexesAtLimit.value ? decksMutations.value : [];
  return {costs: costs};
});

const ability = computed(() => moduleObject.value.ability);

const shredMutations = computed(() => {
  let shreds = moduleObject.value.shreds;
  if (shreds && (isInstalled.value || isUninstalled.value)) {
    shreds = utils.clone(shreds);
    shreds.forEach(s => s.val = Math.floor((s.val ?? 1) * Game.gameConf.multipliers.forgedModuleShred));
  }
  return shreds;
});

const apiPrefix = computed(() => `modules/${rootHolder.value.type}/${rootHolder.value.root._id}/${uid.value}`);

const apiPath = computed(() => `${apiPrefix.value}/forge`);

const forgingButtonMutexInfos = computed(() => isNotStarted.value ? mutexInfos.value : null);

const forgingButtonLabel = computed(() => isNotStarted.value ? 'Build' : 'Resume');

const testId = computed(() => `Module::${uid.value}`);

function install() {
  doPost('install');
}

function uninstall() {
  doPost('uninstall');
}

function useAbility() {
  doPost('useAbility');
  abilityUsed.value = true;
  window.setTimeout(() => abilityUsed.value = false, 3000);
}

function shred() {
  if (window.confirm(`Really shred ${labels.value.name}?`)) {
    api.del(apiPrefix.value);
  }
}

function doPost(path) {
  api.post(`${apiPrefix.value}/${path}`);
}

function addDisabledIf(classes, isDisabled) {
  isDisabled && classes.push('-disabled');
  return classes;
}
</script>
